import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import ExportJsonExcel from 'js-export-excel';
import { ImportExcel } from './../components';
import moment from 'moment';

import {
  EditOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';

const SurveysWithMedia = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }
    let restData = await api
      .get(`/rest/surveysWithMedia?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;

            if (item.sendNotification)
              item.sendNotification = <CheckOutlined />;
            else item.sendNotification = <CloseOutlined />;

            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/surveysWithMedia/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/surveysWithMedia/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let getReport = async (record) => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(
      `/rest/reports/surveysWithMedia?id=${record._id}&lang=${lang}`,
    );
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `${record.name.replace(/ /g, '')}_report.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };
  let downloadExcel = async (record) => {
    var option = {};
    let dataTable = [];
    let query = [];

    query['survey_id'] = record._id;
    let _params = { query };

    let surveysWithMedia =   await  api
    .get('/rest/surveysWithMedia/' + record._id)
    .then(({ data: { result, result_message } }) => {
      return result
    });


    let answers = await api
    .get(`/rest/surveyWithMediaAnswers?${qs.stringify(_params)}`, {
      _params,
    })
    .then(({ data }) => {
      if (data.result) {
        return data.result.rows.map((item, key) => {
          if (item.active) item.active = <CheckOutlined />;
          else item.active = <CloseOutlined />;
          item.key = key;
          return item;
        });
      }
    });

    let rows = [];
    let sheetAreas = ['Form İsmi',"Sicil No", "Tarih"];

    await Promise.all(answers.map(async (answer,i) => {
      let row = {
        'Form İsmi': surveysWithMedia.name,
        /*[lang.items.NAME_LASTNAME]: answer.attendee_id
          ? [answer.attendee_id.name, answer.attendee_id.lastname]
              .filter((x) => x)
              .join(" ")
          : "Kullanıcı Bulunamadı",
        [lang.items.PHONE]: answer.attendee_id
          ? "tel:" + answer.attendee_id.phone
          : "-",
        [lang.items.EMAIL]: answer.attendee_id
          ? answer.attendee_id.email
          : "",
        //TC: answer.attendee_id ? answer.attendee_id.tc : "",
        [lang.items.BIRTHDAY]: answer.attendee_id
          ? answer.attendee_id.birthdayString
          : "",
        [lang.items.COMPANY]: answer.attendee_id
          ? answer.attendee_id.company
          : "",
        [lang.items.DEPARTMENT]: answer.attendee_id
          ? answer.attendee_id.department
          : "",
        [lang.items.POSITION]: answer.attendee_id
          ? answer.attendee_id.position
          : "",*/
        "Sicil No": answer.attendee_id
          ? answer.attendee_id.registerNo
          : "",
        "Tarih": moment(answer.created)
          .add(3, "H")
          .format("YYYY-MM-DDTHH:mm:ss"),
      };
      let sheetAreasQuestions=[]
      await Promise.all(answer.questions.map((q) => {
        console.log("qqqqqq    ",q)
        if (q.type === "Free") {
          row[q.name] = q.other;
        } else if (q.type === "Selected") {
          if (q.childType === "Media") {
            let selects = q.choices.filter((c) => c.is_selected);
            row[q.name] = selects
              .map(
                (x) =>
                  (x.media.url ? x.media.url : "") +
                  (x.is_other ? `'${x.other}'` : "")
              )
              .join(", ");
          } else {
            let selects = q.choices.filter((c) => c.is_selected);
            row[q.name] = selects
              .map((x) => x.name + (x.is_other ? `'${x.other}'` : ""))
              .join(", ");
          }
        } else if (q.type === "Media") {
          let selects = q.choices;
          let i = 1;
          selects.map((x) => {
            row[q.name + "(" + i + ")"] =
              (x.media.url ? x.media.url : "") +
              (x.is_other ? `'${x.other}'` : "");
            i = i + 1;
          });
        } else if (q.type === "Date") {
          row[q.name] = moment(q.dateInput)
            .add(3, "H")
            .format("YYYY-MM-DDTHH:mm:ss");
        } else if (q.type === "DateRange") {
          row[q.name] =
            moment(q.dateRangeInputStart)
              .add(3, "H")
              .format("YYYY-MM-DDTHH:mm:ss") +
            " - " +
            moment(q.dateRangeInputEnd)
              .add(3, "H")
              .format("YYYY-MM-DDTHH:mm:ss");
        } else if (q.choices) {
          if(q._id=="7AdN6KsPstgLnTq"){
          let selects = q.choices.filter((c) => c.is_selected);
          row[q.name] = selects
            .map(
              (x) =>{
                if(x.name=="15"){
                  x.name= "0,25" 
                }
                else if(x.name=="30"){
                  x.name= "0,5" 
                }
                else if(x.name=="45"){
                  x.name= "0,75" 
                }
                else if(x.name=="1"){
                  x.name= "1" 
                } else if(x.name=="1,15"){
                  x.name= "1,25" 
                }
                else if(x.name=="1,30"){
                  x.name= "1,5" 
                }
                else if(x.name=="1,45"){
                  x.name= "1,75" 
                }
                else if(x.name=="2"){
                  x.name= "2" 
                }
                else if(x.name=="2,15"){
                  x.name= "2.25" 
                }
                else if(x.name=="2,30"){
                  x.name= "2.5" 
                }
                else if(x.name=="2,45"){
                  x.name= "2.75" 
                }
                else if(x.name=="3"){
                  x.name= "3" 
                }
                return x.name
            })
            .join(" - ");
          }else{
            let selects = q.choices.filter((c) => c.is_selected);
            row[q.name] = selects
              .map(
                (x) =>
                  x.name +
                  (x.media ? (x.media.url ? x.media.url : "") : "") +
                  (x.is_other ? `'${x.other}'` : "")
              )
              .join(" - ");
          }

        }
        if(i==0){
          sheetAreasQuestions.push(q.name)

        }


      }));
      sheetAreas=sheetAreas.concat(sheetAreasQuestions)

      dataTable.push(row);
    }));

    option.fileName = module ? module.name : ""
    option.datas = [
        {
            sheetData: dataTable,
            sheetName: 'sheet',

            sheetFilter: sheetAreas,
            sheetHeader: sheetAreas
        }
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
}
  let columns = [
    {
      title: languagesCms.TITLE,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.LANG,
      dataIndex: 'lang',
      key: 'lang',
      sorter: (a, b) => a.lang - b.lang,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.NOTIFICATION,
      dataIndex: 'sendNotification',
      key: 'sendNotification',
      sorter: (a, b) => a.sendNotification - b.sendNotification,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (user) =>
        user.length > 0 &&
        user.map((e) => <div style={{ marginBottom: 5 }}>{e.name}</div>),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
                   <Button  onClick={()=>downloadExcel(record)}
                            icon={<FileExcelOutlined/>}>{!isSmall && 'Export Excel'}</Button> 
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => getReport(record)}
          >
            {isSmall ? '' : languagesCms.REPORT}
          </Button>
          <Link to={'/surveysWithMedia/answers/' + record._id}>
            <Button icon={<FileDoneOutlined />}>
              {isSmall ? '' : languagesCms.ANSWERS}
            </Button>
          </Link>
          <Link to={'/surveysWithMedia/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/surveysWithMedia/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_SURVEY}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default SurveysWithMedia;
